.tracking_DeliveryTable {
  border: 1px solid rgba(0, 0, 0, 0.1);
}
.tracking_DeliveryTable th {
  background: #4cab37;
  color: white;
}
.tracking_DeliveryTable th,
.tracking_DeliveryTable td {
  vertical-align: start;
}
.tracking_ordersummaryinfo p {
  color: grey;
  line-height: 20px;
}
.trackingproductimage {
  width: 100%;
}
.tracking_cancelbutton {
  background: linear-gradient(
    109.6deg,
    rgb(0, 204, 130) 11.2%,
    rgb(58, 181, 46) 91.7%
  );
  color: white;
  border: none;
  border-radius: 5px;
  outline: none;
  padding: 6px 15px;
  width: 20%;
  border: 1px solid #4cab37;
  display: flex;
  align-items: center;
  justify-content: center;
}
.tracking_cancelsuccessbutton {
  background-color: rgb(179, 16, 16);
  color: white;
  border: none;
  border-radius: 5px;
  outline: none;
  padding: 6px 15px;
  width: 20%;
  border: 1px solid rgb(179, 16, 16);
  display: flex;
  align-items: center;
  justify-content: center;
}
.tracking_cancelbutton:hover {
  background: white;
  color: black;
  border: none;
  border-radius: 5px;
  border: 1px solid black;
  padding: 6px 20px;
}
.tracking_trackbutton {
  background: linear-gradient(
    109.6deg,
    rgb(0, 204, 130) 11.2%,
    rgb(58, 181, 46) 91.7%
  );
  color: white;
  border: none;
  border-radius: 5px;
  outline: none;
  padding: 6px 15px;
  width: 20%;
  border: 1px solid #4cab37;
  display: flex;
  align-items: center;
  justify-content: center;
}
.tracking_trackbutton:hover {
  background: white;
  color: black;
  border: none;
  border-radius: 5px;
  border: 1px solid black;
  padding: 6px 20px;
}
@media (min-width: 425px) and (max-width: 1199px) {
  .tracking_cancelbutton {
    width: 35%;
  }
  .tracking_cancelsuccessbutton {
    width: 35%;
  }
  .tracking_trackbutton {
    width: 35%;
  }
}
@media (max-width: 424px) {
  .tracking_cancelbutton {
    width: 100%;
  }
  .tracking_cancelsuccessbutton {
    width: 100%;
  }
  .tracking_trackbutton {
    width: 100%;
  }
}
