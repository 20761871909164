.sidebar {
  position: fixed;
  top: 0;
  left: -250px;
  background-color: #333;
  overflow-x: hidden;
  z-index: 1000;
}

.sidebar.open { 
  left: 0;
  width: 100%;
  height: 100%;
}
 