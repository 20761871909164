.product_middletitle {
  color: #000;
  font-size: 16px;
  font-weight: 600;
}
.product_middlerating {
  color: grey;
  font-size: 12px;
}
.product_middleratingbox {
  border: 1px solid #696969;
  background-color: green;
  color: white;
  padding: 0px 5px;
}
.product_middleprice {
  color: #000;
  font-size: 16px;
  font-weight: 600;
}
.product_middlediscount {
  color: grey;
  font-size: 13px;
  font-weight: 500;
  padding: 0 20px;
  text-decoration: line-through;
}
.product_middleoff {
  color: #000;
  font-size: 13px;
  font-weight: 600;
}
.product_middletax {
  color: #000;
}
.product_middletaxtag {
  border: 0.5px solid grey;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 10px;
}
.product_middletag {
  background-color: green;
  border-radius: 5px;
  color: #fff;
  font-size: 11px;
  margin: 0;
  padding: 4px 9px;
}
.product_middletagtext {
  font-size: 12px;
}
.product_middletaglink {
  font-size: 12px;
}
.product_middletagcartbutton {
  background: rgba(76, 171, 55, 1);
  color: white;
  padding: 5px 40px;
  border-radius: 5px;
  border: 1px solid white;
}
.product_middletagcartbutton:hover {
  background: rgb(255, 255, 255);
  color: rgb(0, 0, 0);
  padding: 5px 40px;
  border-radius: 5px;
  border: 1px solid rgb(53, 52, 52);
}
.product_middleoriginal p {
  background-color: #f2f2f2;
  border-radius: 5px;
  display: inline;
  font-size: 12px;
  padding: 5px;
}
.product_middledeliverydetail {
  background-color: #ecebeb;
  padding: 10px;
}
.product_middledeliverytext {
  font-size: 14px;
}
.product_middleabout img {
  height: 100%;
  width: 100%;
}
.product_middleabout p {
  font-size: 13px;
}
.product_middleslider ul li {
  align-items: center;
  display: flex;
  justify-content: center;
  list-style: none;
}
.product_description h3 {
  font-size: 20px;
}
.product_description p {
  color: grey;
  font-size: 14px;
}
.product_reviewsmaindiv {
  box-shadow: -1px 2px 13px 1px #ebe7e79c;
  padding: 10px;
  border: 5px;
}
/* .product_topreviews {
  padding: 10px 20px;
} */
.productreview_input:focus {
  box-shadow: none;
  border-color: grey;
}
.productreview_submit_button {
  background: rgba(76, 171, 55, 1);
  color: white;
  padding: 5px 20px;
  border-radius: 5px;
  border: none;
}
.productreview_submit_button:hover {
  background-color: white;
  color: black;
  padding: 5px 20px;
  border-radius: 5px;
  border: 1px solid black;
}
.product_imagebackground {
  background: rgba(238, 238, 238, 1);
  border-radius: 5px;
}
.product_image {
  width: 100%;
}
.products_span span {
  margin-right: 8px;
}
.product_highlight {
  color: rgba(76, 171, 55, 1);
}
.product_para {
  color: rgba(151, 151, 151, 1);
}
.product_cardtopportion {
  padding: 10px 16px;
}
.product_cardtitle {
  color: rgba(151, 151, 151, 1);
  font-size: 13px;
}
.product_card {
  box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.08);
  border: none !important;
}
.product_button {
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(76, 171, 55, 1);
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
  border: none;
}
.product_button span {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 13px;
}
.diagonal-line-wrapper {
  position: relative;
  display: inline-block;
  color: rgba(191, 191, 189, 1);
}

.diagonal-line-wrapper::before {
  content: "";
  position: absolute;
  top: 66%;
  left: 3px;
  width: 100%;
  height: 1px;
  background-color: rgba(191, 191, 189, 1);
  transform: rotate(-11deg);
  transform-origin: 0 0;
}
.product .react-slider__lens {
  display: none;
}
.product .react-slider__imgZoom {
  display: none;
}
.product .react-slider__container button {
  display: none;
}
.product_size {
  background-color: white;
  border: 0.5px solid rgb(167, 167, 167);
  padding: 4px 20px;
  margin-right: 3px;
  cursor: pointer;
  border-radius: 5px;
  color: grey;
}
.product_color {
  background-color: white;
  border: 0.5px solid rgb(167, 167, 167);
  padding: 4px 20px;
  margin-right: 3px;
  cursor: pointer;
  border-radius: 5px;
  color: grey;
}
.product_color.active {
  background-color: rgba(76, 171, 55, 1);
  border: 0.5px solid white;
  padding: 4px 20px;
  margin-right: 3px;
  cursor: pointer;
  border-radius: 5px;
  color: white;
}
.product_size.active {
  background-color: rgba(76, 171, 55, 1);
  border: 0.5px solid white;
  padding: 4px 20px;
  margin-right: 3px;
  cursor: pointer;
  border-radius: 5px;
  color: white;
}
.review_createdat {
  font-size: 11px;
}
.react-slider__ul img {
  height: 100%;
  width: 100%;
}
.react-slider .react-slider_picture {
  min-height: 170px !important;
}
.product_reviewsimages {
  height: 100px;
  width: 100px;
  cursor: pointer;
}
.review_deletebutton {
  cursor: pointer;
  color: red;
}
.review_deletebuttontext {
  color: red;
  cursor: pointer;
}
.product_addReview {
  border: 0.5px solid #efefef;
  padding: 20px;
  border-radius: 10px;
}
.slider_mainimage {
  height: 300px;
  width: 100%;
  border: 1px solid #efefef;
  border-radius: 5px;
  margin-bottom: 10px;
}
.slider_smallimage {
  height: 70px;
  width: 70px;
  border: 1px solid #efefef;
  border-radius: 5px;
  margin-bottom: 5px;
}
@media (max-width: 992px) {
  .product_middleabout img {
    height: 125px;
    width: 125px;
  }
}

/* ////////////////////////// */

.react-slider__areaZoom img {
  height: 370px !important;
}
