.profile_container {
  border: 2.6px solid #cecdcd;
}
.profile_column1 {
  padding: 20px;
}

.profile_container label {
  font-weight: bold;
  margin-bottom: 5px;
}

.profile_label_input {
  display: flex;
  flex-direction: column;
}
.profile_label_checkbox_input {
  display: flex;
  align-items: center;
}
.profile_container input[type="text"],
select {
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 5px;
  outline: none;
}

.profile_container input[type="number"],
select {
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 5px;
  outline: none;
}

.profile_container button {
  background: linear-gradient(
    109.6deg,
    rgb(0, 204, 130) 11.2%,
    rgb(58, 181, 46) 91.7%
  );
  color: white;
  border: none;
  border-radius: 5px;
  height: 40px;
  outline: none;
  width: 100%;
}

.profile_container button:hover {
  background: white;
  color: black;
  border: none;
  border-radius: 5px;
  height: 40px;
  border: 1px solid black;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}
