body {
  overflow-x: hidden !important;
}
.newpassword_title {
  background: -webkit-linear-gradient(
    109.6deg,
    rgb(0, 204, 130) 11.2%,
    rgb(58, 181, 46) 91.7%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 700;
}
.newpassword_button {
  margin-top: 10px;
  background: linear-gradient(
    109.6deg,
    rgb(0, 204, 130) 11.2%,
    rgb(58, 181, 46) 91.7%
  );
  border: none !important;
}
.newpassword-formcontrol {
  border-color: none !important;
  box-shadow: none !important;
}
.newpassword-label {
  font-size: 14px;
}
.newpassword_error {
  color: #d9534f;
  font-size: 12px;
  margin-top: 5px;
}
.confirmpassword_error {
  color: #d9534f;
  font-size: 12px;
  margin-top: 5px;
}
.newpassword_input {
  display: flex;
  align-items: center; 
}

.newpassword_toggle {
  cursor: pointer;
  margin-left: -30px;
}
.newpassword_confirmpassword_input {
  display: flex;
  align-items: center;
}

.newpassword_confirmpassword_toggle {
  cursor: pointer;
  margin-left: -30px;
}
