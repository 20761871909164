.footer_iconimage {
  width: 100px;
  height: 100px;
}
.footer_background {
  background-color: black;
  color: white;
  overflow-x: hidden !important;
}
.footer_address span {
  display: flex;
  align-items: center;
}
.footer_call span {
  display: flex;
  align-items: center;
}
.footer_call a {
  color: white !important;
  text-decoration: none;
}
.footer_icon {
  margin-right: 10px;
  color: #4cab37;
}
.footer_background ul {
  list-style-type: none;
}

.footer_background li:hover {
  text-decoration: underline white;
  cursor: pointer;
}
.footer_googleplay {
  margin-right: 5px;
}
.footer_googleplay img {
  width: 110px;
}
.footer_appstore {
  width: 110px;
}
.footer_gpay {
  margin-right: 12px;
}
.footer_gpay img {
  width: 50px;
}
.footer_mastercard {
  margin-right: 12px;
}
.footer_mastercard img {
  width: 40px;
}
.footer_visa {
  margin-right: 12px;
}
.footer_visa img {
  width: 40px;
}

.footer_amex img {
  width: 56px;
}

@media (max-width: 991px) {
  .footer-headings {
    margin-top: 10px;
  }
  .footer_background li {
    font-size: 13px;
  }
  .footer_background span {
    font-size: 13px;
  }
}
