
body{
    overflow-x:hidden !important;
}
.contact-card {
    border: 1px solid #ccc;
    border-radius: 10px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
    padding: 20px;
    text-align: center;
    transition: transform 0.3s ease-in-out;
    height: 150px;
}
.contact-icon {
    font-size: 25px;
    color: #007bff;
}
.contact-title {
    margin-top: 16px;
    font-size: 16px;
    font-weight: bold;
    color: #333;
}
.contact-text {
    margin-top: 8px;
    font-size: 14px;
    color: #777;
}
/* Form */
.contact-form-background{
    background:#222;
    padding: 30px;
    border-radius: 10px;
}
.contact-form-heading{
    color:white;
    font-size:20px;
} 
.contact-input:focus{
    box-shadow: none;
    border-color: transparent;
    outline: none;
}
.contact-submit-button{
    background: white;
    padding:5px 20px 5px 20px;
    border-radius: 5px;
    border: none;
    font-size:16px;
}
.contact-submit-button:hover{
    background-color: #111;
    color: white;
        
}

@media (max-width:1400px) and (min-width:992px){
    .contact-text {
        margin-top: 8px;
        font-size: 10px;
        color: #777;
    }
}