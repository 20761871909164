.payment_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #f2f2f2;
    padding:20px;
    border:0.5px solid black;
    background-color: white;
  }
  
  .paypal-buttons-container {
    margin-top: 20px;
  }
  
  
  .paypal-buttons-container .paypal-button {
    color: #fff;
    background-color: #0070ba;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    font-size: 18px;
    cursor: pointer;
    transition: background-color 0.2s;
  }
  
  .paypal-buttons-container .paypal-button:hover {
    background-color: #00558a;
  }