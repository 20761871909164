.category_filter {
  padding: 10px 30px;
}
.category_filter ul li {
  font-size: 14px;
  color: rgba(151, 151, 151, 1);
}
.category_filter
  .css-j204z7-MuiFormControlLabel-root
  .MuiFormControlLabel-label {
  font-size: 14px;
  color: rgba(151, 151, 151, 1);
}
.category_filter .css-hyxlzm svg {
  font-size: 14px;
}
.category_filtertop h4 {
  font-size: 16px;
  font-weight: 700;
}
.category_filtertop span {
  color: #4cab37;
  cursor: pointer;
  font-size: 13px;
}
.category_categories span {
  /* color: #4cab37; */
  cursor: pointer;
  font-size: 13px;
}
.category_reviewsclear {
  color: #4cab37;
  cursor: pointer;
  font-size: 13px;
}
.category_brandsclear {
  color: #4cab37;
  cursor: pointer;
  font-size: 13px;
}
.category_brandsheading {
  font-size: 16px;
  font-weight: 700;
}
.category_ratingbox {
  border: 1px solid #696969;
  background-color: green;
  color: white;
  padding: 0 5px;
  font-size: 12px;
}
.category_categories h4 {
  font-size: 16px;
  font-weight: 700;
}
.category_categories ul li {
  cursor: pointer;
}
.category_categoriesli.active {
  color: #4cab37 !important;
}
.category_deliveryday h4 {
  font-size: 16px;
  font-weight: 700;
}
.category_customerReviews h4 {
  font-size: 14px;
  font-weight: 700;
}
.category_largeappliance h4 {
  font-size: 16px;
  font-weight: 700;
}
.category_categories ul {
  list-style-type: none;
}
.category_filterprice {
  z-index: -1;
}
.category_filterprice h4 {
  font-size: 14px;
  font-weight: 700;
}
.category_filterprice span {
  color: #4cab37;
  cursor: pointer;
  font-size: 13px;
}
.category_brandsheading {
  font-size: 14px;
  font-weight: 700;
}
.category_searchfield {
  width: 100%;
}
.categoryproduct_imagebackground {
  background: rgba(238, 238, 238, 1);
  border-radius: 5px;
}
.categoryproduct_image {
  width: 100%;
  height: 200px;
}
.categoryproducts_span span {
  margin-right: 8px;
}
.categoryproduct_highlight {
  color: rgba(76, 171, 55, 1);
}
.categoryproduct_para {
  color: rgba(151, 151, 151, 1);
}
.categoryproduct_cardtopportion {
  padding: 10px 16px;
  cursor: pointer;
}
.categoryproduct_cardtitle {
  color: rgba(151, 151, 151, 1);
  font-size: 13px;
}
.categoryproduct_card {
  box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.08);
  border: none !important;
}
.categoryproduct_cardtext {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.categoryproduct_button {
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(76, 171, 55, 1);
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
  border: none;
}
.categoryproduct_button span {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 13px;
}
.diagonal-line-wrapper {
  position: relative;
  display: inline-block;
  color: rgba(191, 191, 189, 1);
}

.diagonal-line-wrapper::before {
  content: "";
  position: absolute;
  top: 66%;
  left: 3px;
  width: 100%;
  height: 1px;
  background-color: rgba(191, 191, 189, 1);
  transform: rotate(-11deg);
  transform-origin: 0 0;
}

.category_paginate .pagination {
  display: flex;
  justify-content: center;
  margin-top: 65px;
}

.category_paginate .pagination a {
  padding: 8px 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.category_paginate .pagination a.active {
  color: white !important;
  background: linear-gradient(
    89.69deg,
    #2a50ce 3.03%,
    #9573ec 81.25%
  ) !important;
}

.category_paginate .pagination a:hover {
  background: linear-gradient(
    109.6deg,
    rgb(0, 204, 130) 11.2%,
    rgb(58, 181, 46) 91.7%
  );
  color: white !important;
}

.category_paginate .pagination li:hover {
  text-decoration: none !important;
}

.category_paginate .pagination a.disabled {
  cursor: not-allowed !important;
  opacity: 0.5;
}

.category_paginate .pagination .previous a {
  color: black;
  text-decoration: none;
}

.category_paginate .pagination .next a {
  color: black;
  text-decoration: none;
}

.category_paginate .active-page a {
  background: linear-gradient(
    109.6deg,
    rgb(0, 204, 130) 11.2%,
    rgb(58, 181, 46) 91.7%
  ) !important;
  color: white !important;
}

.category_paginate .pagination li {
  margin: 0px 1px 0px 1px;
}
.category_filterprice button {
  padding: 5px 30px;
}

.category-scroll-column {
  -ms-overflow-style: none;
  scrollbar-width: none;
  max-height: 100vh;
  overflow-y: auto;
}
.category-scroll-column::-webkit-scrollbar {
  display: none;
}
/* .category-scroll-column {
  max-height: 100vh;
  overflow-y: auto;
}

.category-scroll-column::-webkit-scrollbar-thumb {
  background-color: #888;
} */

.category-scroll-column::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}

@media (max-width: 380px) {
  .category_paginate .pagination a {
    padding: 8px 10px !important;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
}

@media (min-width: 991px) and (max-width: 1440px) {
  .category_filter {
    padding: 10px 0px;
  }
}
.category-loader-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.category-loader-container .MuiCircularProgress-root {
  color: #36d7b7;
}
