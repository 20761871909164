.login_heading_title {
  background: -webkit-linear-gradient(
    109.6deg,
    rgb(0, 204, 130) 11.2%,
    rgb(58, 181, 46) 91.7%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 700;
}

.signin-label {
  font-size: 14px;
}

.signin-button {
  margin-top: 10px;
  background: linear-gradient(
    109.6deg,
    rgb(0, 204, 130) 11.2%,
    rgb(58, 181, 46) 91.7%
  );
  border: none !important;
  box-shadow: none !important;
}

.signin-formcontrol {
  border-color: none !important;
  box-shadow: none !important;
}

.signin-para {
  margin-top: 10px;
}
.login_error {
  color: #d9534f;
  font-size: 12px;
  margin-top: 5px;
}
.forgot-password {
  color: #007bff;
  text-decoration: underline;
  cursor: pointer;
  font-size: 16px;
}

.password-input {
  display: flex;
  align-items: center;
}

.password-toggle {
  cursor: pointer;
  margin-left: -30px;
}
