.myaddresses_DeliveryTable {
  border: 1px solid rgba(0, 0, 0, 0.1);
}
.myaddresses_DeliveryTable th {
  background: #4cab37;
  color: white;
}
.myaddresses_DeliveryTable th,
.myaddresses_DeliveryTable td {
  vertical-align: start;
}
.myaddresses_DeliveryButton {
  background: linear-gradient(
    109.6deg,
    rgb(0, 204, 130) 11.2%,
    rgb(58, 181, 46) 91.7%
  );
  color: white;
  border: none;
  border-radius: 5px;
  height: 40px;
  outline: none;
  border: 1px solid #4cab37;
  padding: 10px 20px;
}
.myaddresses_DeliveryButton:hover {
  background: white;
  color: black;
  border: none;
  border-radius: 5px;
  height: 40px;
  border: 1px solid black;
  padding: 10px 20px;
}

.myaddresses_addressinfo p {
  color: grey;
  line-height: 20px;
}
.myaddresses_removebutton {
  background: linear-gradient(
    109.6deg,
    rgb(0, 204, 130) 11.2%,
    rgb(58, 181, 46) 91.7%
  );
  color: white;
  border: none;
  border-radius: 5px;
  height: 40px;
  outline: none;
  padding: 10px 20px;
}

.myaddresses_removebutton:hover {
  background: white;
  color: black;
  border: none;
  border-radius: 5px;
  height: 40px;
  border: 1px solid black;
  padding: 10px 20px;
}
