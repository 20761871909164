.banner_container {
  overflow-x: hidden !important;
}
.banner_contructionimage {
  width: 100%;
  height: 280px;
}
.banner_electronicimage {
  width: 100%;
  height: 100%;
}
.banner_hairimage {
  width: 100%;
  height: 100%;
}
.banner_background {
  background: rgba(236, 255, 232, 1);
}
.banner_constructionhead {
  font-size: 23px;
  font-weight: bold;
}
.banner_constructionpara {
  font-size: 13px;
  color: rgba(0, 0, 0, 0.5);
}
.banner_constructionbutton {
  background: rgba(76, 171, 55, 1);
  color: white;
  padding: 5px 25px;
  border-radius: 5px;
  border: none;
  font-size: 13px;
}
.banner_electronichead {
  font-size: 23px;
  font-weight: bold;
}

.banner_electronicpara {
  font-size: 13px;
  color: rgba(0, 0, 0, 0.5);
}
.banner_electronicbutton {
  background: rgba(76, 171, 55, 1);
  color: white;
  padding: 5px 25px;
  border-radius: 5px;
  border: none;
  font-size: 13px;
}
.banner_hairhead {
  font-size: 23px;
  font-weight: bold;
}

.banner_hairpara {
  font-size: 13px;
  color: rgba(0, 0, 0, 0.5);
}

.banner_hairbutton {
  background: rgba(76, 171, 55, 1);
  color: white;
  padding: 5px 25px;
  border-radius: 5px;
  border: none;
  font-size: 13px;
}

@media (max-width: 767px) {
  .banner_hairpara {
    font-size: 11px;
  }
  .banner_electronicpara {
    font-size: 11px;
  }
  .banner_constructionpara {
    font-size: 11px;
  }
}
