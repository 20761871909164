.hotdeal_container{
  overflow-x: hidden !important;
}
.hotdeal_background {
  background: #4cab37;
 
}
.countdown {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  flex-wrap: wrap;
}

.countdown-circle {
  text-align: center;
  margin: 0 10px;
}

.countdown-item {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 36px;
  font-weight: bold;
  color: #fff;
  border-radius: 50%;
  border: 2px dotted #fff;
  width: 100px;
  height: 100px;
  line-height: 60px;
}

.countdown-label {
  margin-top: 10px;
  font-weight: bold;
  color: #fff;
}

.countdown-colon {
  font-size: 36px;
  font-weight: bold;
  color: #fff;
  margin: 0 5px;
  margin-top: -42px;
}

.hotdeal_head {
  color: #fff;
  font-weight: bold;
}
.hotdeal_para {
  color: #ffffff80;
}
.hotdeal_button {
  color: #00000080;
  background: #ffffff;
  border: none;
  padding: 5px 20px;
  text-decoration: underline;
}
.hotdeal_image {
  width: 100%;
  height: 100%;
}


