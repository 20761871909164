.featured_categories {
  overflow-x: hidden !important;
}
.featuredcategories_heading {
  color: #000000;
  font-weight: bold;
  font-size: 25px;
}
.featuredcategories_imagebackground1 {
  background: rgba(238, 238, 238, 1);
  border-radius: 15px;
  padding: 15px 0px;
  cursor: pointer;
}
.featuredcategories_imagebackground2 {
  border-radius: 50%;
}
.featuredcategories_image {
  width: 100%;
  height: 100px;
}
.featuredcategories_description {
  color: #000000;
  font-weight: bold;
  font-size: 12px;
}
/* @media (min-width: 768px) and (max-width: 1024px) {
  .bottomnavbar_number {
    font-size: 12px;
  }
  .bottomnavbar_contents p {
    font-size: 13px;
  }
  .bottomnavbar_dropdown p {
    font-size: 10px;
  }
} */
