.sidebar3 {
  position: fixed;
  top: 0;
  left: -250px;
  background-color: white;
  overflow-x: hidden;
  z-index: 1000;
  padding: 20px;
}

.sidebar3.open {
  left: 0;
  width: 100%;
  height: 100%;
}

/* .sidebar3_myaccountleftbar {
  border: 1px solid #cecdcd;
  padding: 20px;
} */
.sidebar3_account_setting {
  list-style-type: none;
}
.sidebar3_account_setting li {
  color: #7e7e7e;
  cursor: pointer;
}
.sidebar3_account_setting li:hover {
  text-decoration: underline;
}
.sidebar3_my_stuff {
  list-style-type: none;
}
.sidebar3_my_stuff li {
  color: #7e7e7e;
  cursor: pointer;
}
.sidebar3_my_stuff li:hover {
  text-decoration: underline;
}
.sidebar3_myaccountleftbarbox2 {
  border: 1px solid #cecdcd;
  padding: 10px;
}
.sidebar3_myaccountleftbarbox3 {
  border: 1px solid #cecdcd;
  padding: 10px;
}
.sidebar3_myaccountleftbarbox4 {
  border: 1px solid #cecdcd;
  padding: 10px;
  cursor: pointer;
}
.sidebar3_myaccountleftbarbox5 {
  border: 1px solid #cecdcd;
  padding: 10px;
  cursor: pointer;
}
.sidebar3_uploadImageButton {
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(76, 171, 55, 1);
  color: white;
  padding: 5px 25px;
  border-radius: 3px;
  outline: none;
  border: 1px solid rgba(76, 171, 55, 1);
}
.sidebar3_uploadImageButton:hover {
  display: flex;
  align-items: center;
  justify-content: center;
  background: white;
  color: rgba(76, 171, 55, 1);
  padding: 5px 25px;
  border-radius: 5px;
}

