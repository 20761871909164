.forgotpassword_left {
  background: linear-gradient(
    109.6deg,
    rgb(0, 204, 130) 11.2%,
    rgb(58, 181, 46) 91.7%
  );
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 54px;
  font-family: "Brygada 1918";
}
.forgotpassword_right {
  display: flex;
  justify-content: center;
  flex-direction: column; 
}
.forgotpassword_logo {
  width: 80px;
  height: 80px;
  cursor: pointer;
}
.forgotpassword_right h3 {
  font-size: 26px;
  font-weight: 700;
}
.forgotpassword_right input[type="email"] {
  border: 1px solid grey;
  border-radius: 5px;
  font-size: 13px;
  outline: none;
  padding: 10px 20px;
  width: 100%;
}
.forgotpassword_button {
    background: linear-gradient(
      109.6deg,
      rgb(0, 204, 130) 11.2%,
      rgb(58, 181, 46) 91.7%
    );
    color: white;
    border: none;
    border-radius: 5px;
    height: 40px;
    outline: none;
  }
  .forgotpassword_button:hover {
    background: white;
    color: black;
    border: none;
    border-radius: 5px;
    height: 40px;
    border: 1px solid black;
  }

  .forgotpassword_error {
    color: #d9534f;
    font-size: 12px;
    margin-top: 5px;
  }

  @media (max-width: 992px) {
    .forgotpassword_left {
      display: none;
    }
    .forgot_password {
      display: flex;
      justify-content: center;
      flex-direction: column;
      height: 100vh;
    }
  }
