.bestproducts_container {
  overflow-x: hidden !important;
}
.bestproduct_heading {
  font-size: 30px;
}
.bestproduct_imagebackground {
  background: rgba(238, 238, 238, 1);
  border-radius: 5px;
}
.bestproduct_image {
  width: 100%;
  height: 200px;
}
.bestproducts_span span {
  margin-right: 8px;
}
.bestproduct_highlight {
  color: rgba(76, 171, 55, 1);
}
.bestproduct_para {
  color: rgba(151, 151, 151, 1);
}
.bestproduct_cardtopportion {
  padding: 10px 16px;
}
.bestproduct_cardtitle {
  color: rgba(151, 151, 151, 1);
  font-size: 13px;
}
.bestproduct_card {
  box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.08);
  border: none !important;
  cursor: pointer;
}
.bestproduct_button {
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(76, 171, 55, 1);
  color: white;
  padding: 5px 25px;
  border-radius: 3px;
  margin-left: 15px;
  outline: none;
  border: 1px solid rgba(76, 171, 55, 1);
}
.bestproduct_button:hover {
  display: flex;
  align-items: center;
  justify-content: center;
  background: white;
  color: rgba(76, 171, 55, 1);
  padding: 5px 25px;
  border-radius: 5px;
}
.bestproduct_button span {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 13px;
}
.bestdiagonal-line-wrapper {
  position: relative;
  display: inline-block;
  color: rgba(191, 191, 189, 1);
}

.diagonal-line-wrapper::before {
  content: "";
  position: absolute;
  top: 66%;
  left: 3px;
  width: 100%;
  height: 1px;
  background-color: rgba(191, 191, 189, 1);
  transform: rotate(-11deg);
  transform-origin: 0 0;
}

.bestproduct_cardtext {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
