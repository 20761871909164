.checkout_image {
  border-radius: 5px;
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.checkout_row {
  border: 2px solid #efefef;
  padding: 20px 10px;
  border-radius: 10px;
}
/* .checkout_address {
  border: 2px solid #efefef;
  padding: 20px;
  border-radius: 10px;
  min-height: 200px;
  max-height: 400px;
  overflow-y: scroll;
} */
.checkout_cartitems {
  max-height: 400px;
  overflow-y: scroll;
}
.checkout_cartitems {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.checkout_cartitems::-webkit-scrollbar {
  display: none;
}
.checkout_address {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.checkout_address::-webkit-scrollbar {
  display: none;
}
.checkout_DeliveryButton {
  background: #000000;
  color: #ffffff;
  border: none;
  border-radius: 5px;
  border: 1px solid black;
  padding: 3px 20px;
}
.checkout_DeliveryButton:hover {
  color: #000000;
  background-color: #ffffff;
  border: none;
  border-radius: 5px;
  outline: none;
  border: 1px solid #000000;
  padding: 3px 20px;
}
.checkout_addnewaddressbutton {
  background: #000000;
  color: #ffffff;
  border: none;
  border-radius: 5px;
  border: 1px solid black;
  padding: 3px 20px;
}
.checkout_addnewaddressbutton:hover {
  color: #000000;
  background-color: #ffffff;
  border: none;
  border-radius: 5px;
  outline: none;
  border: 1px solid #000000;
  padding: 3px 20px;
}
.checkout_addressinfo p {
  color: grey;
  line-height: 20px;
}
.checkout_alladdressdata {
  max-height: 400px;
  border: 1px solid #ccc;
  overflow: auto;
}
.checkout_paymentbutton {
  background: #000000;
  color: #ffffff;
  border: none;
  border-radius: 5px;
  border: 1px solid black;
  padding: 5px 20px;
  width: 50%;
}
.checkout_paymentbutton:hover {
  color: #000000;
  background-color: #ffffff;
  border: none;
  border-radius: 5px;
  outline: none;
  border: 1px solid #000000;
  padding: 5px 20px;
  width: 50%;
}
.checkoutcart_info p {
  color: grey;
  line-height: 20px;
}
.checkoutcart_image {
  width: 100%;
  height: 200px;
}
.checkoutcart_deletebutton {
  cursor: pointer;
  font-size: 16px;
  color: red;
}
.checkoutcart_deletebuttontext {
  cursor: pointer;
  font-size: 14px;
  color: red;
}
.checkoutcart_quantity {
  background-color: #f7f5f5;
  border: 0.5px solid #dcdcdc;
  border-radius: 5px;
  padding: 5px 3px;
  width: 30%;
}
.checkoutcart_quantity svg {
  cursor: pointer;
}
.checkoutcart_total {
  color: #7b7a7a;
  font-size: 16px;
  font-weight: 700;
}
.checkoutcart_couponremovebutton {
  background: linear-gradient(
    109.6deg,
    rgb(0, 204, 130) 11.2%,
    rgb(58, 181, 46) 91.7%
  );
  color: white;
  border: 1px solid #4cab37;
  border-radius: 2px;
  outline: none;
  padding: 2px 20px;
}
.checkoutcart_checkout {
  border: 2px solid #ddd;
  border-radius: 7px;
  padding: 20px;
}
.checkout_cartitems {
  border: 2px solid #ddd;
  border-radius: 7px;
  padding: 20px;
}
.checkout_address {
  border: 2px solid #ddd;
  padding: 20px;
  border-radius: 10px;
  min-height: 200px;
  max-height: 400px;
  overflow-y: scroll;
}
.checkout_homepage {
  color: #007bff;
  text-decoration: underline;
  cursor: pointer;
  font-size: 16px;
}

.payment_selection {
  border: 2px solid #ddd;
  padding: 20px;
  border-radius: 10px;
}

@media (max-width: 550px) {
  .cart_quantity {
    width: "30%";
  }
}
@media (max-width: 420px) {
  .cart_quantity {
    width: "30%";
  }
}

@media (max-width: 767px) {
  .checkout_image {
    display: none;
  }
  .checkout_address {
    margin-top: 5px;
  }
}
@media (min-width: 990px) and (max-width: 1200px) {
  .checkoutcart_quantity {
    width: 40%;
  }
}

/* @media (max-width: 767px) {
  .checkout_address {
    margin-top: 5px;
  }
  .checkout_addressmobile {
    border: 2px solid #efefef;
    padding: 20px;
    border-radius: 10px;
    min-height: 200px;
    max-height: 400px;
    overflow-y: scroll;
    margin-top: 5px;
  }
} */
