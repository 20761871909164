.sidebar2 {
  position: fixed;
  top: 0;
  left: -250px;
  background-color: white;
  overflow-x: hidden;
  z-index: 1000;
  padding: 20px;
}

.sidebar2.open {
  left: 0;
  width: 100%;
  height: 100%;
}


@media (max-width: 990px) {
  .sidebar2 h6 {
    font-size: 13px;
  }
}
