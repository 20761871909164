.update_formcontrol {
  border-color: none !important;
  box-shadow: none !important;
}
.update_heading_title {
  background: -webkit-linear-gradient(
    109.6deg,
    rgb(0, 204, 130) 11.2%,
    rgb(58, 181, 46) 91.7%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 700;
}
.update_label{
    font-size:15px;
    margin-top:0.5rem;
} 
.update_addressmodal select{
    margin-bottom:0px !important;
}
.update_button{
    background: linear-gradient(
    109.6deg,
    rgb(0, 204, 130) 11.2%,
    rgb(58, 181, 46) 91.7%
  ); 
  color: white;
  border: none;
  border-radius: 5px;
  height: 40px;
  outline: none;
  margin-top: 10px;
}
.address_label_checkbox_input{
  display: flex;
  align-items: center;
} 
