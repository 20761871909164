body {
  overflow-x: hidden !important;
}
.faq_heading {
  font-size: 24px;
  background: -webkit-linear-gradient(
    109.6deg,
    rgb(0, 204, 130) 11.2%,
    rgb(58, 181, 46) 91.7%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: bold;
}
.faq_container {
  min-height: 750px;
}
.faq_image {
  width: 100%;
  max-height: 400px;
  height: auto;
}
.accordion {
  --bs-accordion-btn-icon-width: 1rem;
  --bs-accordion-active-color: #4cab37 !important;
  --bs-accordion-btn-focus-box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0);
  --bs-accordion-active-bg: -webkit-linear-gradient(
    #084fc7 49.1%,
    #9573ec 91.76%
  );
  padding: 0px !important;
}
