.cart_container {
  min-height: 50vh;
}
.cart_Table {
  border: 1px solid rgba(0, 0, 0, 0.1);
}
.cart_Table th {
  background: #4cab37;
  color: white;
}
.cart_Table th,
.cart_Table td {
  vertical-align: start;
}
.cart_info p {
  color: grey;
  line-height: 20px;
}
.cart_image {
  width: 100%;
  height: 200px;
}
.cart_deletebutton {
  cursor: pointer;
  font-size: 16px;
  color: red;
}
.cart_deletebuttontext {
  cursor: pointer;
  font-size: 14px;
  color: red;
}
.cart_quantity {
  background-color: #f7f5f5;
  border: 0.5px solid #dcdcdc;
  border-radius: 5px;
  padding: 5px 3px;
  width: 15%;
}
.cart_quantity svg {
  cursor: pointer;
}
.cart_checkout {
  border: 2px solid #ddd;
  border-radius: 7px;
  padding: 20px;
}
.cart_total {
  color: #7b7a7a;
  font-size: 16px;
  font-weight: 700;
}
.cartcheckout_button {
  background: linear-gradient(
    109.6deg,
    rgb(0, 204, 130) 11.2%,
    rgb(58, 181, 46) 91.7%
  );
  color: white;
  border: none;
  border-radius: 5px;
  height: 40px;
  outline: none;
  width: 100%;
}
.cartcheckout_button:hover {
  background: white;
  color: black;
  border: none;
  border-radius: 5px;
  height: 40px;
  border: 1px solid black;
}
.cart_coupon {
  color: #000;
  font-size: 14px;
  height: 35px;
  outline: none;
  padding: 5px 20px;
  background-color: #fff;
  border: 1px solid #000;
  border-radius: 5px;
  font-weight: 500;
  margin-right: 10px;
}
.cart_couponremovebutton {
  background: linear-gradient(
    109.6deg,
    rgb(197, 63, 63) 11.2%,
    rgb(243, 16, 16) 91.7%
  );
  color: white;
  border: none  ;
  border-radius: 5px;
  outline: none;
  padding: 4px 20px;
  font-size: 14px;
}

.cart_couponapplybutton {
  background: linear-gradient(
    109.6deg,
    rgb(0, 204, 130) 11.2%,
    rgb(58, 181, 46) 91.7%
  );
  color: white;
  border: 1px solid #4cab37;
  border-radius: 5px;
  outline: none;
  padding: 2px 20px;
  font-size: 14px;
}

.cart_couponlist {
  display: flex;
  flex-direction: column;
  margin-top: 5px;
  border: 0.5px solid #ddd;
  border-radius: 5px;
  background-color: white;
  padding: 10px;
  cursor: pointer;
}
.cart_couponlist p {
  color: #7b7a7a;
  font-weight: 700;
  font-size: 14px;
}
.cart_couponlist.active {
  background: linear-gradient(
    109.6deg,
    rgb(0, 204, 130) 11.2%,
    rgb(58, 181, 46) 91.7%
  );
}
.cart_couponlist.active h6 {
  color: black;
}
.cart_couponlist.active p {
  color: white;
}
.loader-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
}

.loader-container .MuiCircularProgress-root {
  color: #36d7b7;
}

@media (max-width: 550px) {
  .cart_quantity {
    width: 30% ;
  }
}
@media (max-width: 420px) {
  .cart_quantity {
    width: 40%;
  }
}
