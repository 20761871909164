.myorder_tableresponsive{
  overflow-x: hidden;
}
.myorderTable {
  border: 1px solid rgba(0, 0, 0, 0.1);
}
.myorderTable th {
  background: #4cab37;
  color: white;
}
.myorder-image {
  width: 50%;
  height: 150px;
}
.myorderTable th,
.myorderTable td {
  vertical-align: middle;
}
.myorder_addbutton { 
  background: linear-gradient(
    109.6deg,
    rgb(0, 204, 130) 11.2%,
    rgb(58, 181, 46) 91.7%
  );
  color: white;
  border: none;
  border-radius: 5px;
  height: 40px;
  outline: none;
  width: 100%;
  cursor: pointer;
}
.myorder_addbutton:hover {
  background: white;
  color: black;
  border: none;
  border-radius: 5px;
  height: 40px;
  border: 1px solid black;
}

.myorder_tableresponsive .pagination {
  display: flex;
  justify-content: center;
  margin-top: 65px;
}

.myorder_tableresponsive .pagination a {
  padding: 8px 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.myorder_tableresponsive .pagination a.active {
  color: white !important;
  background: linear-gradient(
    89.69deg,
    #2a50ce 3.03%,
    #9573ec 81.25%
  ) !important;
}

.myorder_tableresponsive .pagination a:hover {
  background: linear-gradient(
    109.6deg,
    rgb(0, 204, 130) 11.2%,
    rgb(58, 181, 46) 91.7%
  );
  color: white !important;
}

.myorder_tableresponsive .pagination li:hover {
  text-decoration: none !important;
}

.myorder_tableresponsive .pagination a.disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

.myorder_tableresponsive .pagination .previous a {
  color: black;
  text-decoration: none;
}

.myorder_tableresponsive .pagination .next a {
  color: black;
  text-decoration: none;
}

.myorder_tableresponsive .active-page a {
  background: linear-gradient(
    109.6deg,
    rgb(0, 204, 130) 11.2%,
    rgb(58, 181, 46) 91.7%
  ) !important;
  color: white !important;
}


.myorder_tableresponsive .pagination li {
  margin: 0px 1px 0px 1px;
}

@media (max-width: 425px) {
  .myorder_tableresponsive .pagination a {
    padding: 8px 8px !important;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
}
@media (max-width: 320px) {
  .myorder_tableresponsive .pagination a {
    padding: 6px 6px !important;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
}

