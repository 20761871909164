.electronicproduct_container {
  overflow-x: hidden !important;
}
.electronic_card {
  box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.08);
  border: none !important;
}
.electronic_cardtopportion {
  padding: 10px 16px;
}
.electronic_imagebackground {
  background: rgba(238, 238, 238, 1);
  border-radius: 5px;
  cursor: pointer;
}
.electronic_image {
  width: 100%;
  height: 200px;
}
.electronic_cardtitle {
  color: #4cab37;
  font-size: 13px;
}
.electronic_span span {
  margin-right: 8px;
}
.electronic_button {
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(76, 171, 55, 1);
  color: white;
  padding: 5px 25px;
  border-radius: 3px;
  margin-left: 15px;
  outline: none;
  border: 1px solid rgba(76, 171, 55, 1);
}
.electronic_button:hover {
  display: flex;
  align-items: center;
  justify-content: center;
  background: white;
  color: rgba(76, 171, 55, 1);
  padding: 5px 25px;
  border-radius: 5px;
}
.electronic_button span {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 13px;
}
.diagonal-line-wrapper {
  position: relative;
  display: inline-block;
  color: rgba(191, 191, 189, 1);
}

.diagonal-line-wrapper::before {
  content: "";
  position: absolute;
  top: 66%;
  left: 3px;
  width: 100%;
  height: 1px;
  background-color: rgba(191, 191, 189, 1);
  transform: rotate(-11deg);
  transform-origin: 0 0;
}
.owl-theme .owl-dots .owl-dot.active span,
.owl-theme .owl-dots .owl-dot:hover span {
  background: blue !important;
}
.electronic_products {
  color: #000000;
  font-weight: bold;
  font-size: 25px;
}
.electronic_viewall {
  color: #00000099;
  font-size: 17px;
  cursor: pointer;
}
.slick-initialized .slick-slide {
  padding: 10px;
}

.electronic_cardtext {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@media (min-width: 320px) and (max-width: 768px) {
  .electronic_products {
    color: #000000;
    font-weight: bold;
    font-size: 20px;
  }
  .electronic_viewall {
    color: #00000099;
    font-size: 17px;
    cursor: pointer;
  }
}

@media (max-width: 319px) {
  .electronic_products {
    color: #000000;
    font-weight: bold;
    font-size: 15px;
  }
  .electronic_viewall {
    color: #00000099;
    font-size: 15px;
    cursor: pointer;
  }
}
