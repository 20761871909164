@import url("https://fonts.googleapis.com/css2?family=Brygada+1918:ital,wght@1,600&family=Nunito:wght@300&family=Poppins&family=Public+Sans&family=Roboto:ital,wght@0,400;1,300&display=swap");

.signup_left {
  background: linear-gradient(
    109.6deg,
    rgb(0, 204, 130) 11.2%,
    rgb(58, 181, 46) 91.7%
  ); 
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 54px;
  font-family: "Brygada 1918";
}
.signup_right {
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.signup_logo {
  width: 80px;
  height: 80px;
  cursor: pointer;
}
.signup_right h3 {
  font-size: 26px;
  font-weight: 700;
}
.signup_right input[type="password"],
.signup_right input[type="text"] {
  border: 1px solid grey;
  border-radius: 5px;
  font-size: 13px;
  outline: none; 
  padding: 10px 20px;
  width: 100%;
}
.signup_password {
  position: relative;
}
.signuppassword_toggle {
  cursor: pointer;
  font-size: 20px;
  position:absolute;
  top: 20px;
  right:10px;
}
.signup_confirmpassword {
  position: relative;
}
.confirmpassword_toggle {
  cursor: pointer;
  font-size: 20px;
  position:absolute;
  top: 20px;
  right:10px;
}
.signup_button {
  background: linear-gradient(
    109.6deg,
    rgb(0, 204, 130) 11.2%,
    rgb(58, 181, 46) 91.7%
  );
  color: white;
  border: none;
  border-radius: 5px;
  height: 40px;
  outline: none;
}
.signup_button:hover {
  background: white;
  color: black;
  border: none;
  border-radius: 5px;
  height: 40px;
  border: 1px solid black;
}
.signup_alreadyaccount {
  color: grey;
  font-size: 13px;
}
.signup_alreadyaccount span {
  color: #007bff;
  text-decoration: underline;
  cursor: pointer;
  font-size: 16px;
}
.signup_error {
  color: #d9534f;
  font-size: 12px;
  margin-top: 5px;
}

@media (max-width: 992px) {
  .signup_left {
    display: none;
  }
  .signup_form {
    display: flex;
    justify-content: center;
    flex-direction: column;
    height: 100vh;
  }
}

@media (max-width: 425px) {
  .signup_alreadyaccount {
    font-size: 12px;
  }
  .signup_alreadyaccount span {
    font-size: 12px;
  }
}