.otp_verify_button {
  background: linear-gradient(
    109.6deg,
    rgb(0, 204, 130) 11.2%,
    rgb(58, 181, 46) 91.7%
  );
  color: white;
  border: none;
  border-radius: 5px;
  height: 40px;
  outline: none;
}
.otp_verify_button:hover {
  background: white;
  color: black;
  border: none;
  border-radius: 5px;
  height: 40px;
  border: 1px solid black;
}
