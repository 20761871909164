.hairperfector_background {
  background-image: url("../../public/middlebanner2.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  height: 550px;
  overflow-x: hidden !important;
  overflow-y: hidden !important;
}

.banner2image {
  height: 400px;
  width: 100%;
}
.hairperfector_heading1 {
  color: #4cab37;
  font-size: 30px;
  font-weight: bold;
}
.hairperfector_heading2 {
  color: #000000;
  font-size: 40px;
  font-weight: bold;
  line-height: 50px;
}
.hairperfector_para {
  color: #00000080;
  font-size: 18px;
}

.hairperfector_button button {
  color: #00000080;
  border: none;
  background: #ffffff;
  padding: 10px 25px;
}

@media (max-width: 768px) {
  .hairperfector_background {
    background: radial-gradient(
      79.68% 79.68% at 43.04% 50%,
      #fffefe 0%,
      #d1d0d0 100%
    );
  }
  .banner2image {
    height: 180px;
  }
}
