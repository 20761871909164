.myaccountleftbar {
  border: 1px solid #cecdcd;
  padding: 20px;
}
.account_setting {
  list-style-type: none;
}
.account_setting li {
  color: #7e7e7e;
  cursor: pointer;
}
.account_setting li:hover {
  text-decoration: underline;
}
.my_stuff {
  list-style-type: none;
}
.my_stuff li {
  color: #7e7e7e;
  cursor: pointer;
}
.my_stuff li:hover {
  text-decoration: underline;
}
.myaccountleftbarbox2 {
  border: 1px solid #cecdcd;
  padding: 10px;
}
.myaccountleftbarbox3 {
  border: 1px solid #cecdcd;
  padding: 10px;
}
.myaccountleftbarbox4 {
  border: 1px solid #cecdcd;
  padding: 10px;
  cursor: pointer;
}
.myaccountleftbarbox5 {
  border: 1px solid #cecdcd;
  padding: 10px;
  cursor: pointer;
}
.uploadImageButton {
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(76, 171, 55, 1);
  color: white;
  padding: 5px 25px;
  border-radius: 3px;
  outline: none;
  border: 1px solid rgba(76, 171, 55, 1);
}
.uploadImageButton:hover {
  display: flex;
  align-items: center;
  justify-content: center;
  background: white;
  color: rgba(76, 171, 55, 1);
  padding: 5px 25px;
  border-radius: 5px;
}
@media (min-width: 1200px) and (max-width: 1400px) {
  .account_setting li {
    font-size: 15px;
  }
}
@media (min-width: 991px) and (max-width: 1200px) {
  .account_setting li {
    font-size: 13px;
  }
}
@media (min-width: 991px) and (max-width: 1300px) {
  .myaccountleftbar h6 {
    font-size: 11px;
  }
}
