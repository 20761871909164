body {
  overflow-x: hidden !important;
}
.resetpassword_title {
  background: -webkit-linear-gradient(
    109.6deg,
    rgb(0, 204, 130) 11.2%,
    rgb(58, 181, 46) 91.7%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 700;
}
.resetpassword_button {
  margin-top: 10px;
  background: linear-gradient(
    109.6deg,
    rgb(0, 204, 130) 11.2%,
    rgb(58, 181, 46) 91.7%
  );
  border: none !important;
}
.resetpassword-formcontrol {
  border-color: none !important;
  box-shadow: none !important;
}
.resetpassword-label {
  font-size: 14px;
}
.resetpassword_error {
  color: #d9534f;
  font-size: 12px;
  margin-top: 5px;
}
.oldpassword_error {
  color: #d9534f;
  font-size: 12px;
  margin-top: 5px;
}
.resetconfirmpassword_error {
  color: #d9534f;
  font-size: 12px;
  margin-top: 5px;
}
.resetpassword_input {
  display: flex;
  align-items: center;
}

.resetpassword_toggle {
  cursor: pointer;
  margin-left: -30px;
}
.resetpassword_confirmpassword_input {
  display: flex;
  align-items: center;
}

.resetpassword_confirmpassword_toggle {
  cursor: pointer;
  margin-left: -30px;
}
