.navbar_background {
  background: #000000;
  overflow-x: hidden !important;
}
.navbar_toggle svg {
  color: white;
}
.navbar_icon {
  width: 80px;
  height: 80px;
  cursor: pointer;
}
.search-bar {
  background: #fff;
  display: flex;
  width: 100%;
  padding: 12px 10px;
  border-radius: 5px 0px 0px 5px;
}
.select {
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 13px;
}
.search-bar input {
  flex: 1;
  border: none;
  outline: none;
  font-size: 16px;
}
.navbar_search {
  border-radius: 3px;
  position: static;
  display: block;
}

.search_results {
  position: absolute;
  background-color: #f9f9f9;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  padding: 12px 16px;
  z-index: 1;
  width: 911px;
  max-height: 300px;
  overflow-y: auto;
}
.search_results::-webkit-scrollbar {
  display: none;
}

.search_results {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

@media screen and (max-width: 1400px) {
  .search_results {
    width: 777px;
    z-index: 999;
  }
}
@media screen and (max-width: 1199px) {
  .search_results {
    width: 562px;
  }
}
@media screen and (max-width: 991px) {
  .search_results {
    width: 282px;
  }
}
@media screen and (max-width: 767px) {
  .search_results {
    width: 462px;
  }
}
/* @media screen and (max-width: 490px) {
  .search_results {
    width: 415px;
  }
} */
@media screen and (max-width: 575px) {
  .search_results {
    width: 496px;
  }
}
/* @media screen and (max-width: 440px) {
  .search_results {
    width: 400px;
  }
} */
@media screen and (max-width: 540px) {
  .search_results {
    width: 462px;
  }
}
@media screen and (max-width: 486px) {
  .search_results {
    width: 408px;
  }
}
@media screen and (max-width: 430px) {
  .search_results {
    width: 352px;
  }
}
@media screen and (max-width: 425px) {
  .search_results {
    width: 347px;
  }
}
@media screen and (max-width: 414px) {
  .search_results {
    width: 336px;
  }
}
@media screen and (max-width: 412px) {
  .search_results {
    width: 334px;
  }
}
@media screen and (max-width: 390px) {
  .search_results {
    width: 312px;
  }
}
@media screen and (max-width: 375px) {
  .search_results {
    width: 297px;
  }
  .navbar_searchicon {
    padding: 4px 16px;
  }
}
@media screen and (max-width: 360px) {
  .search_results {
    width: 282px;
  }
}

@media screen and (max-width: 320px) {
  .search_results {
    width: 242px;
  }
}

@media screen and (max-width: 280px) {
  .search_results {
    width: 202px;
  }
}

.search_results ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.search_results li {
  padding: 10px;
  cursor: pointer;
  color: #00000080;
  transition: background-color 0.3s, color 0.3s;
  font-size: 14px;
}

.search_results li:hover {
  color: white;
  background: #4cab37;
}
.navbar_searchicon {
  background-color: #4cab37;
  padding: 11px 16px;
  display: flex;
  align-items: center;
  font-size: 26px;
  color: white;
  border-radius: 0px 10px 10px 0px;
  border: none;
}
.navbar_cart {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 20px;
  cursor: pointer;
  line-height: 0.8;
}
.navbar_cart span {
  display: flex;
  align-items: center;
  color: #000000;
  font-weight: bold;
}
.navbar_cart svg {
  font-size: 25px;
  color: white;
}
.navbar_carticon {
  color: green !important;
}
.navbar_account {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 20px;
  cursor: pointer;
  line-height: 0.8;
}
.navbar_account svg {
  font-size: 24px;
  color: white;
}
.navbar_account span {
  display: flex;
  align-items: center;
  color: #000000;
  font-weight: bold;
}
.navbar_wishlist {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  line-height: 0.8;
}
.navbar_wishlist svg {
  font-size: 27px;
  color: white;
}
.navbar_wishlist span {
  display: flex;
  align-items: center;
  color: #000000;
  font-weight: bold;
  font-size: 10px;
}
.navbar_space {
  font-size: 14px;
}

.select {
  cursor: pointer;
  position: relative;
  display: inline-block;
  padding: 10px;
}

.navbar_dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #fff;
  border: none;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: none;
  z-index: 99999;
  width: 100%;
  border-radius: 10px;
}

.select:hover .navbar_dropdown {
  display: block;
}

.navbar_dropdown ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.navbar_dropdown li {
  padding: 10px;
  cursor: pointer;
  color: #00000080;
}
.navbar_dropdown li:hover {
  color: white;
  background: #4cab37;
}
.navbar_right p {
  margin: 0px;
}

@media (min-width: 768px) and (max-width: 1200px) {
  .navbar_account {
    font-size: 15px;
  }
  .navbar_space {
    font-size: 15px;
  }
  .navbar_wishlist {
    font-size: 15px;
  }
}
@media (max-width: 767px) {
  .navbar_icon {
    width: 60px;
    height: 60px;
    cursor: pointer;
  }
  .navbar_account {
    font-size: 13px;
  }
  .navbar_space {
    font-size: 13px;
  }
  .navbar_wishlist {
    font-size: 13px;
  }
}
