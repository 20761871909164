.deleteaccount_title {
    background: -webkit-linear-gradient(
      109.6deg,
      rgb(0, 204, 130) 11.2%,
      rgb(58, 181, 46) 91.7%
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: 700;
  }
  .deleteaccount_button {
    background: linear-gradient(
      109.6deg,
      rgb(0, 204, 130) 11.2%,
      rgb(58, 181, 46) 91.7%
    );
    border: none !important;
  }