.form {
  width: 100%;
  height: 100%;
}
.formheading {
  width: 800px;
  height: auto;
  border: 2px solid #efefef;
  border-radius: 10px;
  padding: 20px;
}
.find_image {
  height: 150px;
  width: 150px;
}
.find_text {
  color: rgba(151, 151, 151, 1);
}
.find_row {
  border: 2px solid #efefef;
  padding: 20px;
  border-radius: 10px;
  font-size: 14px;
}
.find_button {
  background: rgba(76, 171, 55, 1);
  color: white;
  padding: 5px 20px;
  border-radius: 5px;
  border: 1px solid rgba(76, 171, 55, 1);
}
.find_button:hover {
  background-color: white;
  color: black;
  padding: 5px 20px;
  border-radius: 5px;
  border: 1px solid black;
}
.form-control{
  box-shadow:none !important;
}
