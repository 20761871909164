.wishlist_container {
  min-height: 50vh;
}
.wishlist_table {
  border: 1px solid rgba(0, 0, 0, 0.1);
}
.wishlist_table th {
  background: #4cab37;
  color: white;
}
.wishlist_table th,
.wishlist_table td {
  vertical-align: start;
}
.wishlist_info p {
  color: grey;
  line-height: 20px;
}
.wishlist_image {
  width: 100%;
  height: 100%;
}
.wishlist_deletebutton {
  cursor: pointer;
  color: red;
  font-size: 24px;
}
.wishlist_quantity {
  background-color: #f7f5f5;
  border: 0.5px solid #dcdcdc;
  border-radius: 5px;
  padding: 10px 5px;
}
.wishlist_quantity svg {
  cursor: pointer;
}
.wishlist_addbutton {
  background: linear-gradient(
    109.6deg,
    rgb(0, 204, 130) 11.2%,
    rgb(58, 181, 46) 91.7%
  );
  color: white;
  border: none;
  border-radius: 5px;
  padding: 6px;
  outline: none;
  width: 20%;
  cursor: pointer;
  border: 1px solid white;
}
.wishlist_addbutton:hover {
  background: white;
  color: black;
  border: none;
  border-radius: 5px;
  padding: 6px;
  border: 1px solid black;
}
.wishlist_deletebutton {
  cursor: pointer;
  font-size: 16px;
}
.wishlist_data label {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.wishlist_data .wishlist_image {
  width: 100%;
  height: 200px;
}

.wishlist_deletebuttontext {
  color: red;
  font-size: 14px;
  cursor: pointer;
}

@media (max-width: 991px) {
  .wishlist_addbutton {
    width: 35%;
  }
}
