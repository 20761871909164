.bottomnavbar_background {
  background-color: #4cab37;
  /* overflow-x: hidden !important; */
  /* overflow-y: hidden !important; */
}
.bottomnavbar_dropdown {
  background: rgba(255, 255, 255, 1);
  padding: 8px 8px;
  border-radius: 5px;
}
.bottomnavbar_customer {
  font-size: 30px;
  color: white;
  margin-right: 10px;
}
.bottomnavbar_number {
  font-size: 13px;
  cursor:pointer;
}
.bottomnavbar_support {
  font-size: 10px;
}
.bottomnavbar_contents p {
  font-size: 13px;
}
.bottomnavbar_contents p:hover {
  cursor: pointer;
  text-decoration: underline;
}
.bottomnavbar_dropdown {
  cursor: pointer;
  position: relative;
  display: inline-block;
  padding: 6px 50px;
}

.bottom_navbardropdown {
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #fff;
  border: none;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: none;
  width: 100%;
  border-radius: 10px;
  z-index: 1;
  height: 200px;
  overflow-y: auto;
}

.bottom_navbardropdown {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.bottom_navbardropdown::-webkit-scrollbar {
  display: none;
}

.bottomnavbar_dropdown:hover .bottom_navbardropdown {
  display: block;
}

.bottom_navbardropdown ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.bottom_navbardropdown li {
  padding: 10px;
  cursor: pointer;
  color: #00000080;
  font-weight: bold;
  font-size: 14px;
}
.bottom_navbardropdown li:hover {
  color: white;
  background: #4cab37;
}

@media (min-width: 768px) and (max-width: 1030px) {
  .bottomnavbar_number {
    font-size: 12px;
  }
  .bottomnavbar_contents p {
    font-size: 13px;
  }
  .bottomnavbar_dropdown p {
    font-size: 14px;
  }
  .bottomnavbar_dropdown {
    padding: 6px 12px;
  }
  .bottomnavbar_dropdown p {
    font-size: 13px;
  }
}

@media (max-width: 767px) {
  .bottomnavbar_background {
    display: none;
  }
}
@media (min-width: 1030px) and (max-width: 1400px) {
  .bottomnavbar_dropdown {
    padding: 6px 20px;
  }
  .bottomnavbar_dropdown p {
    font-size: 14px;
  }
}
@media (min-width: 768px) and (max-width: 990px) {
  .bottomnavbar_contents p {
    font-size: 11px;
}
.bottomnavbar_number{
  font-size: 11px;
}
}

.bottomnavbar_background .container .center_items ul {
  margin: 0px;
  padding: 0px;
}
.bottomnavbar_background .container .center_items ul li {
  list-style-type: none;
}
