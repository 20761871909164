.topnavbar_background{
    background: #000000;
    border-bottom: 1px solid white;
    overflow-x: hidden !important;
}
.topnavbar_divider {
    margin:0px 6px;
}
.topnavbar_left {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.topnavbar_highlight{
    color: #4CAB37; 
}
.topnavbar_ul {
    list-style-type: none;
    margin-bottom:0px;
    padding:0;
}
.topnavbar_ul li{ 
    font-size:11px;
}
.topnavbar_ul li svg{
    font-size:18px;
}
.topnavbar_rightbox{
    background: #2C2C2C;
    font-size:11px;
    padding: 5px 10px;
    border-radius: 2px;
    cursor: pointer;
}
.topnavbar_rightbox svg{
    font-size:18px;   
}

@media (min-width: 768px) and (max-width: 991px) {
    .topnavbar_ul li{ 
        font-size:9px;
    }
    .topnavbar_ul li svg{
        font-size:15px;
    }
    .topnavbar_rightbox{
        background: #2C2C2C;
        font-size:11px;
        padding: 2px 7px;
        border-radius: 2px;
        cursor: pointer;
    }
}
@media (max-width:500px) {
    .topnavbar_ul li{ 
    font-size:8px;
}
.topnavbar_ul li svg{
    font-size:16px;
}
}