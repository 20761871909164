.blog_container {
  overflow-x: hidden !important;
}
.blog_heading {
  font-size: 30px;
}
.blog_highlight {
  color: #4cab37;
}
.blog_para {
  color: #00000080;
}
.blog_card {
  box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.08);
  border: none !important;
}
.blog_card .card-body {
  min-height: 160px;
}

.blog_date {
  color: #4cab37;
  font-size: 12px;
}
.blog_cardtitle {
  color: #000000;
  font-weight: bold;
  font-size: 20px;
}
.blog_cardtext {
  color: #00000080;
}
.blog_cardbutton {
  background: rgba(76, 171, 55, 1);
  color: white;
  padding: 5px 25px;
  border-radius: 5px;
  border: 0.5px solid rgba(76, 171, 55, 1);
}
.blog_cardbutton:hover {
  background: white;
  color: rgba(76, 171, 55, 1);
  padding: 5px 25px;
  border-radius: 5px;
}
